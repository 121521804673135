import React from 'react';
import { Card, CardContent } from './components/ui/card';
import { 
  Github, 
  Linkedin, 
  Mail, 
  Train, 
  Bus, 
  Bike,
  Globe,
  MessageCircle,
  Network
} from 'lucide-react';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50">
      {/* Hero Section with enhanced styling */}
      <header className="py-24 px-4 text-center relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-100/30 to-purple-100/30 animate-gradient-x"></div>
        <div className="max-w-4xl mx-auto relative">
          <h1 className="text-5xl md:text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-6">
            Mark Ballew
          </h1>
          <p className="text-xl md:text-2xl text-gray-700 font-light">
            Engineer by trade, advocate by passion, explorer at heart
          </p>
        </div>
      </header>

      <main className="max-w-4xl mx-auto px-4 pb-20 space-y-12">
        {/* About Section */}
        <section className="transform hover:-translate-y-1 transition-transform duration-300">
          <Card className="overflow-hidden border-none shadow-lg bg-white/80 backdrop-blur-sm">
            <CardContent className="p-8">
              <h2 className="text-2xl font-semibold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
                About Me
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    Hi, I'm Mark Ballew. I live in San Francisco with my girlfriend, Lara, and our two cats, Ziggy and Zuzu. Fun fact: Ziggy and Zuzu are brothers, and Zuzu is a polydactyl cat with extra toes!
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    I'm a seasoned DevOps professional with a passion for computer security. In my spare time, I create everything from props to T-shirts using 3D printing, especially for events like races, birthdays, and holidays.
                  </p>
                  <div className="flex flex-col space-y-3 mt-6">
                    <div className="flex items-center space-x-3 text-gray-600 bg-blue-50 p-3 rounded-lg">
                      <Globe className="w-5 h-5 text-blue-500" />
                      <span>Based in San Francisco</span>
                    </div>
                    <div className="flex items-center space-x-3 text-gray-600 bg-purple-50 p-3 rounded-lg">
                      <MessageCircle className="w-5 h-5 text-purple-500" />
                      <span>Learning Portuguese & Spanish</span>
                    </div>
                    <div className="flex items-center space-x-3 text-gray-600 bg-indigo-50 p-3 rounded-lg">
                      <span className="font-medium">🖨️ 3D Printing Enthusiast</span>
                    </div>
                  </div>
                </div>
                <div>
                  <img 
                    src="/sfcat.jpg"
                    alt="San Francisco themed illustration" 
                    className="rounded-xl shadow-lg w-full hover:scale-105 transition-transform duration-300"
                  />
                  <p className="text-sm text-gray-500 mt-3 italic text-center">
                    SF City scape
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </section>

        {/* Work Section */}
        <section className="transform hover:-translate-y-1 transition-transform duration-300">
          <Card className="border-none shadow-lg bg-white/80 backdrop-blur-sm">
            <CardContent className="p-8">
              <h2 className="text-2xl font-semibold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
                Professional Work
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl p-8">
                  <h3 className="text-xl font-medium mb-3 text-blue-700">
                    <a 
                      href="https://myshake.berkeley.edu" 
                      className="hover:text-blue-800 transition-colors duration-300 flex items-center gap-2"
                    >
                      MyShake Project <Network className="w-5 h-5" />
                    </a>
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    I am doing full-time Devops for the MyShake project, an earthquake early warning system at UC Berkeley's Seismo Lab. 
                    I collaborate daily with scientists to combine DevOps expertise with computer security, ensuring our critical 
                    infrastructure remains protected, fast, and reliable.
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <img 
                    src="/siesmo.jpg"
                    alt="Seismic wave visualization" 
                    className="rounded-xl shadow-lg hover:scale-105 transition-transform duration-300"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </section>

        {/* Transportation Advocacy */}
        <section className="transform hover:-translate-y-1 transition-transform duration-300">
          <Card className="border-none shadow-lg bg-white/80 backdrop-blur-sm">
            <CardContent className="p-8">
              <h2 className="text-2xl font-semibold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
                Transportation Advocacy
              </h2>
              <div className="grid md:grid-cols-3 gap-6 mb-8">
                <div className="flex flex-col items-center p-6 bg-gradient-to-b from-blue-50 to-blue-100 rounded-xl hover:scale-105 transition-transform duration-300">
                  <Train className="w-10 h-10 text-blue-600 mb-3" />
                  <span className="text-gray-700 font-medium">Rail Transit</span>
                </div>
                <div className="flex flex-col items-center p-6 bg-gradient-to-b from-green-50 to-green-100 rounded-xl hover:scale-105 transition-transform duration-300">
                  <Bus className="w-10 h-10 text-green-600 mb-3" />
                  <span className="text-gray-700 font-medium">Bus Networks</span>
                </div>
                <div className="flex flex-col items-center p-6 bg-gradient-to-b from-orange-50 to-orange-100 rounded-xl hover:scale-105 transition-transform duration-300">
                  <Bike className="w-10 h-10 text-orange-600 mb-3" />
                  <span className="text-gray-700 font-medium">Bike Infrastructure</span>
                </div>
              </div>
              <p className="text-gray-700 leading-relaxed">
                For over a decade, I've dedicated myself to improving urban transit, collaborating with agencies like 
                the SFMTA to craft policies that enhance rail, bus, bike, and multimodal transportation. As the chair 
                of Rescue Muni, I lead efforts to advocate for enhanced rail service across San Francisco, the Bay Area, 
                and California.
              </p>
            </CardContent>
          </Card>
        </section>

        {/* Contact Section */}
        <section className="transform hover:-translate-y-1 transition-transform duration-300">
          <Card className="border-none shadow-lg bg-white/80 backdrop-blur-sm">
            <CardContent className="p-8">
              <h2 className="text-2xl font-semibold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
                Let's Connect
              </h2>
              <div className="flex flex-wrap gap-4">
                <a href="mailto:ballew@sublinear.net" 
                   className="flex items-center space-x-3 px-6 py-3 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl hover:scale-105 transition-transform duration-300">
                  <Mail className="w-5 h-5 text-blue-600" />
                  <span className="text-gray-700 font-medium">Email</span>
                </a>
                <a href="https://www.linkedin.com/in/ballew/" 
                   className="flex items-center space-x-3 px-6 py-3 bg-gradient-to-r from-blue-50 to-purple-100 rounded-xl hover:scale-105 transition-transform duration-300">
                  <Linkedin className="w-5 h-5 text-blue-600" />
                  <span className="text-gray-700 font-medium">LinkedIn</span>
                </a>
                <a href="https://twitter.com/ballew" 
                   className="flex items-center space-x-3 px-6 py-3 bg-gradient-to-r from-purple-50 to-purple-100 rounded-xl hover:scale-105 transition-transform duration-300">
                  <span className="font-bold text-lg">𝕏</span>
                  <span className="text-gray-700 font-medium">X</span>
                </a>
                <a href="https://github.com/markballew" 
                   className="flex items-center space-x-3 px-6 py-3 bg-gradient-to-r from-gray-50 to-gray-100 rounded-xl hover:scale-105 transition-transform duration-300">
                  <Github className="w-5 h-5 text-gray-700" />
                  <span className="text-gray-700 font-medium">GitHub</span>
                </a>
              </div>
            </CardContent>
          </Card>
        </section>
      </main>

      <footer className="text-center py-8 text-gray-600">
        <p>&copy; 2024 Mark Ballew</p>
      </footer>
    </div>
  );
};

export default LandingPage;